.SidebarItem {
    display: flex !important;
    margin-top: 0.7rem !important;
    margin-bottom:0.7rem !important;
    overflow:hidden;
    word-break: break-word;
}

div{
    display: block;
}

.SidebarItem-img {
    height: 80px;
    width: 60px;
    min-width: 60px;
    overflow: hidden;
    background-position: center center;
    background-size: cover;
}

.SidebarItem .rounded {
    border-radius: .25rem!important;
}

.SidebarItem .border {
    border:1px solid #dee2e6!important;
}

.campaign-rate{
    border: 1px solid #0089fe;
    padding: 3px;
    border-radius: 4px;
    display: inline-block;
    color: #0089fe;
    font-size: 1em;
    font-weight: 400;
    text-align: center!important;
}