html{
  height:100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #212529;
  bottom: 0;
  height:100%;
  background-color:#eaeef2 !important;
}

div#root{
  height:100%;
}

.text-mblight{
  color: RGBA(0,0,0,0.25);
}

.bebas{
  font-family: bebas-neue, sans-serif; 
}
.Logo img {
    max-width: 220px;
    height: 25px!important;
}

img {
    /* vertical-align: middle; */
    border-style: none;
    margin-right: 0!important;
}

.mobile-show {
    display: none;
}

@media (max-width: 576px) {
    .mobile-hide {
        display: none!important;
    }
    .mobile-show {
        display: initial!important;
    }
    .Logo img {
        max-width: 220px;
        height: 25px;
        margin-top:0px;
    }
}
.SearchCampaigns {
    flex: 2 1;
    min-width: 50px;
    height: 51px;
    width: 95%!important;
    width: 80%;
}

form {
    display: block;
    margin-top: 0em;
}

.SearchCampaigns .form-control {
    font-size: 13px;
    font-family: sans-serif;
    padding: .75rem 1rem;
    border-width: 0;
    border-radius: 0;
    border: 1px solid #ced4da;
    background-color:rgb(255, 100, 100)!important;
    /* background-color: #fff; */
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.SearchCampaigns .SearchCampaigns .form-control-dark {
    color: #fff;
    background-color: rgba(255, 255, 255, 0);
    /* border-color: rgba(255, 255, 255, .0); */
    border: none;
    font-family: "Open Sans", sans-serif;
}

.SearchCampaigns .SearchCampaigns .form-control-dark:focus {
    color: #fff;
    background-color: rgb(255, 120, 120)!important;
    border: none;
    box-shadow: none;
    -webkit-appearance: none;
            appearance: none;
}

.SearchCampaigns input::-webkit-input-placeholder {
    color: rgb(255, 255, 255);
}

.SearchCampaigns input::placeholder {
    color: rgb(255, 255, 255);
}

.SearchCampaigns .form-control {
    background-color: black;
}

@media(max-width: 768px) {
    .SearchCampaigns {
        width: 100%;
        background-color:rgb(255, 100, 100)!important;
    }
}

.ui-autocomplete {
    background-color: white;
    border: 1px solid RGBA(0, 0, 0, 0.2);
    position: relative;
    cursor: default;
    overflow: auto;
    overflow-x: hidden;
    white-space: pre;
    overflow-y: auto;
    text-align: left;
    max-width: 720px;
    max-height: 500px;
}

ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
}

.noshow {
    display: none;
}

.ui-menu-items {
    padding: 5px;
    padding-left: 20px;
}

#quicksearch-autocomplete:focus, #quicksearch-autocomplete:hover{
    background:#50CABD;
}
/* .DropdownItems {
    display: none;
    position: relative;
} */

.DropdownItems a {
    size: 100px;
}

.dropdown-toggle{
    cursor:pointer;
}

.dropdown-toggle svg{
    color:RGBA(255,255,255,0.85);
}

.dropdown-toggle svg:hover{
    color:white;
}

.dropdown-toggle::after {
    display:none!important;
}

.font-weight-bold {
    font-weight: 700!important;
}

.feather-icon {
    margin-right: 20px;
    color: white;
}

.dropdown-news-item{
    width:100%;
    min-width:275px;
    max-width:500px;
    word-wrap: break-word;
    white-space: normal !important;
    padding: 0.75rem 1.5rem !important;
}

/* 768px or lower */
@media (max-width: 768px){
    .mobile-hide-dd{
        display: none;
    }
    /* .feather-icon{
        margin-left: 50px;
    } */
}

/* 768px or higher */
@media (min-width: 768px){ 
    .mobile-hide-dd {
        display: visible;
    }
}
.NotificationItems a {
    size: 100px;
}

.dropdown-toggle::after {
    display: none!important;
}

.font-weight-bold {
    font-weight: 700!important;
}

.news-dropdown-menu {
    overflow-x: hidden;
    max-height: 900px;
    font-size: 13px!important;
}

@media(max-width: 768px) {
    .mobile-hide-notif {
        display: none;
    }
    .news-dropdown-menu {
        max-height: 500px;
    }
}
.Toolbar {
    /* mb-bg-red */
    background-color: #fe3234; 
    /* fixed-top */
    /* position: fixed; */
    top: 0;
    right: 0;
    left: 0; 
    z-index: 1030;
    /* p-0 */
    padding: 0!important;
    /* justify-content: space-around; */
}

.fixed-top{
    position: fixed;
}

.navbar-brand {
    display: inline-block;
    background-color: #e92a2a;
    width: 220px;
    white-space: nowrap;
}

.navbar-brand .navbar-dark {
    color: #fff;
}

.Toolbar .mb-icon{
    text-decoration: none;
    height: 50px;
    align-content: center;
    margin: 0px;
}

a.maxmoney-badge-link:hover{
    text-decoration:none;
}

.maxmoney-badge{
    border-radius:20px !important;
    padding: 4px 10px !important;
    background:RGBA(0,0,0,0.3) !important;
    color: #dfb971 !important;
    font-weight:bold !important;
    font-size:13px !important;
    max-height:25px;
}

@media(max-width: 576px){
    .navbar-brand {
        padding-top: .75rem;
        padding-bottom: .75rem;
        font-size: 1rem;
        background-color: #e92a2a;
        width: 60px;
    }
    .desktopOnly{
        display: none;
    }
}

@media(max-width: 768px){
    .desktopOnly{
        display: none;
    }
}
.sidebar .LeftNavigationBar {    
    top: 25;
    height: calc(100vh-48px);    
    overflow-x: hidden;
    overflow-y: auto;
    /* background-color: #181a1d; */
    text-align: left;
    /* padding: 20px; */
    color: #6c757d!important;
}

.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    padding: 48px 0 0;
    box-shadow: inset -1px 0 0 rgba(0,0,0,.1);
    min-width: 150px;
    background-color: #181a1d;
}

.mb-sidebar {
    width: 220px;
}

.sidebar-heading {
    font-size: 12px;
    text-transform: uppercase;
    padding-left: 1rem!important;
    padding-right: 1rem!important;
    margin-top: 1.5rem!important;    
    justify-content: space-between!important;
    display: flex!important;
    align-items: center!important;
}

.sidebar a {
    display: inline-block;
    color: #6c757d;
    padding-right: 0;
}

.nav-link {
    display: block;
    padding: .5rem 1rem; 
    
}

.link-title{
    font-size: 14px;
    font-weight: 500;
    margin-left: -5px;
}

.sidebar a:hover{
    color: #8d969e;
}

.headshot{
    border-radius:300px;
    max-width:100px;
}

@media (max-width: 768px){
    .sidebar {
        display: none;
    }
}

/* .LeftNavigationBar .ContactModal {
    width: 100%;
    font: black!important;
    font-size: 25px!important;
} */

.contact-modal .Modal {
    max-width: 300px;
    font-size: 14px;
    color: black!important; 
}

.nav-link.active-nav {
    color: #007bff;
}

.nav-link.active-nav:hover {
    color: #007bff;
}

.left-nav-modal {
    position: fixed;
    width: 10%;
}
.feather-icon {
    margin-right: 20px;
    color: white;
}


.bottom-nav{
    display: none;
}

.mobile-only {
    display: none;
}

@media(max-width: 768px){
    .bottom-nav {
        background-color: #181a1d;
        position: fixed;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1030;
        display: flex;
        height: 55px;
        justify-content: space-between;
    }  
    .mobile-only {
        display: flex;
    }
}

.SidebarItem {
    display: flex !important;
    margin-top: 0.7rem !important;
    margin-bottom:0.7rem !important;
    overflow:hidden;
    word-break: break-word;
}

div{
    display: block;
}

.SidebarItem-img {
    height: 80px;
    width: 60px;
    min-width: 60px;
    overflow: hidden;
    background-position: center center;
    background-size: cover;
}

.SidebarItem .rounded {
    border-radius: .25rem!important;
}

.SidebarItem .border {
    border:1px solid #dee2e6!important;
}

.campaign-rate{
    border: 1px solid #0089fe;
    padding: 3px;
    border-radius: 4px;
    display: inline-block;
    color: #0089fe;
    font-size: 1em;
    font-weight: 400;
    text-align: center!important;
}
.RightSidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 100;
    padding: 48px 0 0 7px;
    box-shadow: inset -1px 0 0 rgba(0,0,0,.1);
    min-width: 150px;
    background: white;
    width: 230px;
    margin-top: .5rem;
    font-weight: 400;
    overflow-y: scroll;
    padding-bottom: 0.5rem;
}

/* Adding this to make sidebar invisible below 1200px screen width - TEMP FIX */
.RightSidebar:hover{
    overflow-y: scroll;
}

@media (max-width: 1200px){
    .RightSidebar {
        display: none;
    }
}
.alertPop {
    position: relative;
    /* max-width: 350px */
    width: 350px;
    background: RGBA(0, 0, 0, 0.85);
    color: white;
    text-align: left;
    overflow: hidden;
    margin-bottom: 10px;
}

.alertPop-header {
    padding: 5px 10px;
    background: rgba(0, 0, 0, 0.5);
    font-weight: bold;
}

.alertPop-body {
    padding: 10px;
    color: RGBA(255, 255, 255, 0.75);
    justify-content: space-between;
    display: flex;
}

.alertPopup-appear, .alertPopup-appear-active {
    /* opacity: 0; */
    /* position: relative; */
    bottom: -10px;
    right: -1000px;
}

.alertPopup-enter {
    right: -500px;
}

.alertPopup-enter-active {
    transition: 1000ms;
    right: 0px;
}

.alertPopup-enter-done {
    right: 0px;
}

/* If sliding out of view upon exit */

/* .alertPopup-exit {
    right: 0px;
}

.alertPopup-exit-active {
    right: -800px;
    transition: 1000ms;
} */

/* If fade it out upon exit */

.alertPopup-exit {
    opacity: .5;
    /* right: 0px; */
}

.alertPopup-exit-active {
    opacity: 0;
    transition: 1000ms;
}

.alert-container {
    position: fixed;
    top: 60px;
    right: 10px;
    z-index: 1000;
}
.Content {
    margin-left: 220px;
    /* overflow: visible; */
    /* display: inline-block; */
    /* clear: both; */
    /* width: 100%; */
    /* max-width: calc(100%-450px); */
    padding-left: .5rem!important;
    padding-right: .5rem!important;
    font-size: 14px;
    margin-right: 230px;
    margin-top: 50px;
}

@media (max-width: 1200px){
    .Content {
        margin-right: 0;
    }
}

@media(max-width: 768px){
    .Content {
        margin-top: 50px;
        margin-right: 0;
        margin-left: 0;
        margin-bottom: 0;
    }
}
.custom-control-label {
    position: relative;
    margin-bottom: 0;
    cursor: pointer;
}

label {
    display: inline-block;
}

.mb-radio {
    display: inline-block;
    background: white;
    border-radius: 4px;
    padding: 4px 0px 2px 5px;
    border: 1px solid RGBA(0, 0, 0, 0.1);
    cursor: pointer;
    margin: 2px;
}

.custom-control {
    position: relative;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem;
}

.custom-control-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}
.mb-traffic {
    padding: 2px 5px;
    margin: 3px 2px;
    display: inline-block;
    cursor: default;
}

.mb-traffic.allowed {
    color: #17a2b8;
    border: 1px solid #17a2b8;
    border-radius: 50px;
}

.mb-traffic.not-allowed {
    color: #6c757d;
    text-decoration: line-through;
}
.country-container.open {
    height: auto;
    overflow: auto;
    padding-bottom: 25px;
}

.country-container.closed {
    height: 100px;
    overflow: hidden;
}

.country-container.closed.short {
    height: auto;
    overflow: hidden;
}

.country-overlay {
    padding-top: 15px;
    bottom: 0;
    width: 100%;
}

.country-overlay.open {
    background: none;
}

.country-overlay.closed {
    background: #e5e5e5;
    /* Old browsers */
    /* FF3.6-15 */
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, RGBA(255, 255, 255, 0), #ffffff 60%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e5e5e5', endColorstr='#000000', GradientType=0);
    /* IE6-9 */
}

.LandingPage-empty {
    flex: 1 0 10rem;
    height: 0px;
    margin: 11px;
    margin-bottom: 0;
}
.mb-lp-card.active {
    border: 1px solid #007bff;
}

.mb-lp-card {
    flex: 1 1 11rem;
    margin: 3px;
}

.mb-lp-image {
    height: 100px;
    background-size: cover;
    background-position: center center;
}

a {
    color: #007bff;
    text-decoration: none;
}

@media(min-width: 576px){
    .mb-lp-card {
        width: 100%!important;
    }
}

.col-form-label {
    padding-top: calc(.375rem + 1px);
    padding-bottom: calc(.375rem + 1px);
    font-size: inherit;
    line-height: 1.5;
}

@media(min-width: 576px) {
    .form-inline-label {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0;
    }
    .mb-lp-card {
        width: 100%!important;
    }
}
.Banner .card-body {
    padding: 1.25rem!important;
}

.Banner .modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 1rem;
    border-top: 1px solid #e9ecef;
}

.max-width-250 {
    max-width: 250px;
}

@media(max-width: 576px) {
    .Banner img {
        padding: 0;
        size: 100px!important;
    }
}

@media(min-width: 992px){
    .modal-lg{
        max-width: 800px;
    }
}
.Macro {
    font-size: 14px;
}

pre {
    display: block;
    /* font-size: 13px!important; */
    padding: 9.5px;
    margin: 0 0 10px;
    line-height: 1.43;
    word-break: break-all;
    word-wrap: break-word;
    background-color: #ccc;
    border-radius: 4px;
}
.campaign-left {
    padding-right: 0px!important;
}

@media(max-width: 992px) {
    .campaign-left {
        padding-right: 13px!important;
    }
}

.campaign-image {
    max-height: 241px;
    overflow: hidden;
}

.border-bottom {
    border-bottom: 1px solid #dee2e6;
}

div {
    display: block;
}

.flex-1 {
    flex: 1 1;
}

.height-limited {
    max-height: 350px;
    overflow-y: scroll;
}

/* table{
    display: table;
    border-spacing: 2px;
    border-color: grey;
    border-collapse: collapse;
} */

.table-responsive {
    display: block;
    width: 100%;
    overflow: auto;
    padding: 0;
}

.table {
    margin-bottom: 0px!important;
}

.text-info {
    text-transform: uppercase;
}

.w-100 {
    width: 100%!important;
}

/* .campaign-right {
    padding-left: 4px;
    margin-left: 0px!important;
} */

.badge-success {
    color: #fff;
    background-color: #28a745;
}

input[type=radio] {
    box-sizing: border-box;
    padding: 0;
}

input {
    overflow: visible;
}

#campaign-tracking-blur {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: RGBA(255, 255, 255, 0.80);
    z-index: 9;
    margin-top: -10px;
    border-radius: 4px;
}

.min-width-150 {
    min-width: 150px;
}

.tracking-link-floating {
    position: -webkit-sticky;
    position: sticky;
    top: 60px;
    align-self: flex-start;
    z-index: 1;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .10);
    background: rgba(255, 255, 255, 1);
}

.max-width-100 {
    max-width: 100px;
}

.SingleCampaign .card-body {
    padding: 0;
}

/* For fadeout effect on clicking 'Build tracking link' button */

.fade-overlay-exit {
    opacity: 0.5;
    transition: all 500ms linear;
}

.fade-overlay-exit-active {
    opacity: 0.1;
}

.fade-overlay-exit-done {
    opacity: 0;
}

table#emailrules {
    table-layout: fixed;
    width: 100%!important;
}

table#emailrules td {
    width: 100%!important;
}

.rules-title {
    margin-right: -150px;
    font-weight: 600;
    font-size: 14px;
}
.byrequest-form label{
    font-weight:bold;
    display:block;
}

.byrequest-form input[type="text"]{
    max-width:300px;
}

.byrequest-form input[type="text"].long{
    max-width:500px;
}

.byrequest-form input[type="text"].short{
    max-width:100px;
}

.byrequest-form select{
    max-width:250px;
}
.Campaigncard {
    flex: 1 0 11rem;
    /* flex: 1 0 11rem; */
    margin: 3px;
    display: flex;
}

.mb-card-img-top {
    width: 100%;
    height: 125px;
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
    background-size: cover;
    background-position: center center;
}

.mb-campaign-price {
    border: 1px solid #0089fe;
    padding: 3px;
    border-radius: 4px;
    display: inline-block;
    color: #0089fe;
    font-size: .8rem;
}

.Campaigncard .card-body {
    padding: .5rem!important;
    font-size: .75rem;
}

.mb-card {
    position: relative;
    /* display: flex; */
    flex-direction: column;
    width: 100%;
    min-width: 0px;
    max-width: 320px;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .25rem;
}

.transparent{
    opacity:0;
}

.fade-appear {
    opacity: 0;
    position: relative;
    bottom: -10px;
}

.fade-appear.fade-appear-active {
    /* opacity: 0.3; */
    /* transition: all 1s ease-in-out; */
    opacity: 0;
    bottom:0px;
}

.fade-enter {
    /* opacity: 0.6; */
    opacity: 0;
    position: relative;
    bottom: -10px;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    bottom: 0px;
    transition: all .5s ease-in-out;
}

.fade-enter-done {    
    /* transition-duration: 300ms; */
    transition: all .5s ease-in-out;
}

.highlighted{
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .40);
    border:1px solid black;
}
.mb-card-img-top-loading {
    width: 100%;
    height: 125px;
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
    background-size: cover;
    background-position: center center;
    background:RGBA(0,0,0,0.1);
    color:white;
}
.Campaigncard-empty {
    flex: 1 0 11rem;
    height: 0px;
    margin: 3px;
}
.category .Popular{
    border-bottom:3px solid #0089fe
}


.category .Suggested{
    border-bottom:3px solid #ff9000
}


.category .New{
    border-bottom:3px solid #7200ff
}






.UserDetails {
    background: white;
    overflow: hidden;
    padding: 0.5rem!important;
    text-align: left;
}

.d-inline-block {
    display: inline-block!important;
}

.EarningStats {
    background: white;
    overflow: hidden;
}

.mb-bottom-rounded {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.py-2 {
    padding-bottom: .5rem!important;
    padding-top: .5rem!important;
}

.px-3 {
    padding-right: 1rem!important;
    padding-left: 1rem!important;
}

.border-right {
    border-right: 1px solid #dee2e6!important;
}

#stats-card{
    display: none;
}

@media (max-width: 768px){
    .UserDetails {
        width: 100%;
    }
}

@media (max-width: 1200px){
    #stats-card {
        display: block;
        text-align: left;
    }
    .EarningStats{
        display: none!important;
    }
}
.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
}

.card-header {
    padding: .5rem!important;
    font-weight: bold;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    border-bottom: 1px solid rgba(0,0,0,.125);
}
.mb-slider-sticky {
    position: absolute;
    bottom: 4px;
    right: 10px;
}
.mb-1 {
    margin-bottom: .25rem;
}

.mt-2 {
    margin-top: .5rem;
}

.d-flex {
    display: flex;
}

.mr-2 {
    margin-right: .5rem;
}

.card {
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .25rem;
    text-align: start;
}

.card-body {
    flex: 1 1 auto;
}

.mb-slider-img {
    flex-grow:0;
    flex-shrink:0;
    flex-basis:200px;
    background-size: cover;
    background-position: center center;
}

.mb-slider .card{
    height:180px;
    overflow:hidden;
}

  .mb-slider {
      height: 180px;
}

.fadeIn-appear {
    opacity: 0;
    position: relative;
    bottom: -10px;
}

.fadeIn-appear.fadeIn-appear-active {
    /* opacity: 0.3; */
    /* transition: all 1s ease-in-out; */
    opacity: 0;
}

.fadeIn-enter {
    /* opacity: 0.6; */
    opacity: 0;
    position: relative;
}

.fadeIn-enter.fadeIn-enter-active {
    opacity: 1;
    transition: all .5s ease-in-out;
}

.fadeIn-enter-done {    
    /* transition-duration: 300ms; */
    transition: all .5s ease-in-out;
}
.card-body {
    flex: 1 1 auto;
}

.mb-dashboard-offers {
    margin-top: 7px;
    /* margin-bottom: 70px; */
}

.mb-campaign-container {
    margin-left: -3px;
    margin-right: -3px;
}

@media(max-width: 576px) {
    .mb-dashboard-offers {
        margin-top: .5rem!important;
    }
}
.report-dropdown-toggle{
    cursor:pointer;
    color:#007bff !important;
}
th.sortable{
    cursor: pointer;
    white-space: nowrap;
}
.nav-active {
    border-bottom: 3px solid #0089fe !important;
    font-weight: 700 !important;
    border-radius:0px !important;
}

.active-view-icon {
 color: #0089fe;  
 size: 40px!important; 
}
.mb-bg-white {
    background: white;
}
.Search .table td, .table th {
    vertical-align: middle!important;
    text-align: left;
}

.custom-select {
    font-size: 14px!important;
}
.table.table-card tbody tr .no-border {
    border: none;
}

.table td, .table th {
    padding: .5rem!important;
}

.fa {
    display: inline-block;
    font: normal normal normal 14px;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-feature-settings: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-feature-settings: inherit;
            font-feature-settings: inherit;
}

.Search .ui-autocomplete {
    position: absolute;
    z-index: 100;
    width: 520px;    
}
.maxmoney-gold{
    font-weight:bold;
	color:#d0a85b;
	background: -webkit-linear-gradient(#fff, #b0842e);
	background-clip: text;
	-webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.maxmoney-event{
	border-radius:3px;
	border:1px solid RGBA(255,255,255,0.1);
	margin:10px 0px;
	background-color:RGBA(0,0,0,0.1);
}

.maxmoney-event-date{
	padding:20px;
	background-color:RGBA(255,255,255,0.05);
    min-width:150px;
    text-align:center;
}

.maxmoney-item{
	padding:10px;
	border-left:1px solid RGBA(255,255,255,0.1);
	flex:1 1;
}

.maxmoney-promo-earnings{
	text-align:center;
	padding:0px 25px;
	border-left:1px solid RGBA(255,255,255,0.05);
	border-right:1px solid RGBA(255,255,255,0.05);
}

.maxmoney-optin{
	padding:10px;
	justify-content: flex-end;
}

.maxmoney-promo-symbol{
    max-width:15px;
    margin-top:-3px;
}
.mb-header-title{
    border:#e92a2a 3px solid;
    display:inline-block;
}
.resources-container {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
    /* justify-content: left; */
}

.resource-card {
    flex: 1 0 250px;
    max-width: 300px;
}
.Resourcecard-empty {
    flex: 1 0 190px;
    max-width: 300px;
}
.progress-goal{
    position:absolute;
    right:5px;
    top: 4px;
    color:black;
    z-index:999;
    color:rgb(228, 0, 0);
}
.PersonalInfo .react-tel-input .form-control {
    width: 100%!important;
    height: 40px!important;
}
.card-header{
    padding: .75rem 1.25rem!important;
}
.login-container{
  position:absolute;
  top:0;
  right:0;
  bottom:0;
  left:0;
}

div.signup-container{
  height:100%;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 50px 15px 15px 15px;
  margin: auto;
  z-index:9999 !important;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}

.form-signin .checkbox {
  font-weight: 400;
}

.login-video{
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}
.signup-progress{
    height:5px !important;
}
.form-register .form-control {
    position: relative;
    box-sizing: border-box;
    height: auto;
    padding: 10px;
    font-size: 16px;
}

.form-register .checkbox {
    font-weight: 400;
}

.react-tel-input .form-control {
    padding-left: 48px!important;
}
.register-container{
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
}

div.signup-container{
    height:100%;
}

.form-register {
    width: 100%;
    max-width: 500px;
    padding: 50px 15px 15px 15px;
    margin: auto;
    z-index:9999 !important;
}

.login-video{
position: fixed;
right: 0;
bottom: 0;
min-width: 100%;
min-height: 100%;
}

.z-99{
    z-index:99999;
}

.left-register{
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#12171f+0,07080e+100 */
    background: #12171f; /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(45deg, #12171f 0%,#07080e 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#12171f', endColorstr='#07080e',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    display:flex;
    -webkit-display:flex;
    color:white;
    position:fixed !important;
    height:100%;
}

.right-register{
    display:flex;
    -webkit-display:flex;
    margin-left:40%;
}

.partner-logo{
    max-width:250px;
    width:100%;
    margin-bottom:2rem;
}

.mktg-pop{
    position:absolute;
    z-index: 9999999;
    top: 10px;
    margin-left:auto;
    margin-right:auto;
    left:0;
    right:0;
    text-align:center;
}

@media only screen and (max-height: 850px), screen and (max-width: 1500px){
    .mktg-icons{
        display:none !important;
    }
}

@media only screen and (max-width: 768px){
    .left-register{
        display:none !important;
    }
    .right-register{
        margin:auto;
    }
    .form-register {
        width: 100%;
        max-width: 500px;
        padding: 0px;
        margin: auto;
        z-index:9999 !important;
    }
}
.verify-container {
    width: 100%;
    max-width: 600px;
    margin: auto;
    z-index:9999 !important;
  }
.terms-header{
    margin-left:18.0pt;
    text-indent:-18.0pt;
}

.terms-item{
    margin-left:54.0pt;
    text-indent:-25.65pt;
}

.terms-sub-item{
    margin-left:81.0pt;
    text-indent:-36.0pt;
}

.terms-container{
    width: 100%;
    max-width: 1200px;
    margin: auto;
    z-index:9999 !important;
}
.loading-page-container{
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: auto;
    z-index: 9999 !important;
}
.Footer {
    /* margin-left: 229px; */
    overflow: hidden;
    /* max-width: calc(100%-450px); */
    padding-left: .5rem!important;
    padding-right: .5rem!important;
    font-size: 14px;
    margin-right: 238px;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}

@media (max-width: 1200px){
    .Footer {
        margin-right: 8px;
    }
}

@media(max-width: 768px){
    .Footer {
        margin-bottom: 60px;
    }
}
.system-message-container{
    position:fixed;
    z-index:99999999;
    width:100%;
}

.system-message-container-holder{
    display:inline-block;
    margin:auto;
}

.system-message{    
    top:50px;
    padding:0.5rem 1rem;
    margin-top:1rem;
    border-radius:4px;
    box-shadow:0px 2px 5px RGBA(0,0,0,0.2);
    font-weight:bold;
}

.system-message.success{
    background:#00d961;
    color:RGBA(0,0,0,0.5);
}

.system-message.error{
    background:red;
    color:white;
}

.systemMessageIn-appear {
    opacity: 0;
}

.systemMessageIn-appear.systemMessageIn-appear-active {
    /* opacity: 0.3; */
    /* transition: all 1s ease-in-out; */
    opacity: 0;
}

.systemMessageIn-enter {
    /* opacity: 0.6; */
    opacity: 0;
}

.systemMessageIn-enter.systemMessageIn-enter-active {
    opacity: 1;
    transition: all .5s ease-in-out;
}

.systemMessageIn-enter-done {    
    /* transition-duration: 300ms; */
    transition: all .5s ease-in-out;
}

.systemMessageIn-exit{
  opacity: 0;
}

.systemMessageIn-exit.systemMessageIn-exit-active {
  opacity: 0;
  transition: all .5s ease-in-out;
}

.systemMessageIn-exit-done {    
  /* transition-duration: 300ms; */
  transition: all .5s ease-in-out;
}
.survey-message-success {
    background: #00d961;
    padding: 10px;
    /* padding: 10px!important; */
    margin: 20px;
    color: white;
    border-radius: 3px;
    text-align: left;
    font-size: 14px;
}

.survey-message-text {
    margin-left: 20px;
}

.dropdown-menu {
    max-height: 400px;
    overflow-y: scroll;
}

.dropdown-label:hover {
    cursor: pointer;
    background-color: lightgrey;
}

.IntroModal input[type=checkbox]:checked + label{
    background-color: #337ab7;
    color: white;
    width: 90%;
}

.no-values-selected {
    background-color: rgb(255, 180, 180);
}
.App {
  text-align: center;
  border: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  background: #eaeef2;
}

.Content {
  right: 215px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.seamless-header {
  background: white;
  overflow: hidden;
  padding: 0.5rem!important;
  text-align: left;
  border-bottom-left-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

.bg-dark .card-header {
  background: RGBA(0, 0, 0, 0.2);
}

.image-card{
  flex:1 0 150px;
  max-width:300px;
}

hr.thick{
	border:2px solid;
}

hr.xdark {
  border-color: RGBA(0, 0, 0, 0.7);
}

hr.dark {
  border-color: RGBA(0, 0, 0, 0.2);
}

hr.red {
  border-color: #e92a2a;
}

hr.short {
  width: 40%;
}

hr.medium {
  width: 75%;
}

.split-left {
  padding-right: 0px !important;
}

.table-header-no-border th {
  border-bottom: none !important;
}

.card-header-no-border {
  border-bottom: none !important;
}

.mw-50 {
  max-width: 50%
}

.mw-75 {
  max-width: 75%;
}

@media(max-width: 1200px) {
  .row {
      margin-right: 0px!important;
      margin-left: -15px!important;
  }
  .split-right{
      padding-right:0px!important;
  }
}

@media(max-width: 992px) {
  .split-left, .split-right {
    padding: 0px!important;
  }
  .row {
      margin-right: 0px!important;
      margin-left: 0px!important;
  }
}

@media(max-width: 576px) {
  .no-mobile {
    display: none;
  }
}
