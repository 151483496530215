.NotificationItems a {
    size: 100px;
}

.dropdown-toggle::after {
    display: none!important;
}

.font-weight-bold {
    font-weight: 700!important;
}

.news-dropdown-menu {
    overflow-x: hidden;
    max-height: 900px;
    font-size: 13px!important;
}

@media(max-width: 768px) {
    .mobile-hide-notif {
        display: none;
    }
    .news-dropdown-menu {
        max-height: 500px;
    }
}