.UserDetails {
    background: white;
    overflow: hidden;
    padding: 0.5rem!important;
    text-align: left;
}

.d-inline-block {
    display: inline-block!important;
}

.EarningStats {
    background: white;
    overflow: hidden;
}

.mb-bottom-rounded {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.py-2 {
    padding-bottom: .5rem!important;
    padding-top: .5rem!important;
}

.px-3 {
    padding-right: 1rem!important;
    padding-left: 1rem!important;
}

.border-right {
    border-right: 1px solid #dee2e6!important;
}

#stats-card{
    display: none;
}

@media (max-width: 768px){
    .UserDetails {
        width: 100%;
    }
}

@media (max-width: 1200px){
    #stats-card {
        display: block;
        text-align: left;
    }
    .EarningStats{
        display: none!important;
    }
}
.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
}

.card-header {
    padding: .5rem!important;
    font-weight: bold;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    border-bottom: 1px solid rgba(0,0,0,.125);
}