.App {
  text-align: center;
  border: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  background: #eaeef2;
}

.Content {
  right: 215px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.seamless-header {
  background: white;
  overflow: hidden;
  padding: 0.5rem!important;
  text-align: left;
  border-bottom-left-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

.bg-dark .card-header {
  background: RGBA(0, 0, 0, 0.2);
}

.image-card{
  flex:1 0 150px;
  max-width:300px;
}

hr.thick{
	border:2px solid;
}

hr.xdark {
  border-color: RGBA(0, 0, 0, 0.7);
}

hr.dark {
  border-color: RGBA(0, 0, 0, 0.2);
}

hr.red {
  border-color: #e92a2a;
}

hr.short {
  width: 40%;
}

hr.medium {
  width: 75%;
}

.split-left {
  padding-right: 0px !important;
}

.table-header-no-border th {
  border-bottom: none !important;
}

.card-header-no-border {
  border-bottom: none !important;
}

.mw-50 {
  max-width: 50%
}

.mw-75 {
  max-width: 75%;
}

@media(max-width: 1200px) {
  .row {
      margin-right: 0px!important;
      margin-left: -15px!important;
  }
  .split-right{
      padding-right:0px!important;
  }
}

@media(max-width: 992px) {
  .split-left, .split-right {
    padding: 0px!important;
  }
  .row {
      margin-right: 0px!important;
      margin-left: 0px!important;
  }
}

@media(max-width: 576px) {
  .no-mobile {
    display: none;
  }
}