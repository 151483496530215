.bottom-nav{
    display: none;
}

.mobile-only {
    display: none;
}

@media(max-width: 768px){
    .bottom-nav {
        background-color: #181a1d;
        position: fixed;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1030;
        display: flex;
        height: 55px;
        justify-content: space-between;
    }  
    .mobile-only {
        display: flex;
    }
}
