.SearchCampaigns {
    flex: 2;
    min-width: 50px;
    height: 51px;
    width: 95%!important;
    width: 80%;
}

form {
    display: block;
    margin-top: 0em;
}

.SearchCampaigns .form-control {
    font-size: 13px;
    font-family: sans-serif;
    padding: .75rem 1rem;
    border-width: 0;
    border-radius: 0;
    border: 1px solid #ced4da;
    background-color:rgb(255, 100, 100)!important;
    /* background-color: #fff; */
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.SearchCampaigns .SearchCampaigns .form-control-dark {
    color: #fff;
    background-color: rgba(255, 255, 255, 0);
    /* border-color: rgba(255, 255, 255, .0); */
    border: none;
    font-family: "Open Sans", sans-serif;
}

.SearchCampaigns .SearchCampaigns .form-control-dark:focus {
    color: #fff;
    background-color: rgb(255, 120, 120)!important;
    border: none;
    box-shadow: none;
    appearance: none;
}

.SearchCampaigns input::placeholder {
    color: rgb(255, 255, 255);
}

.SearchCampaigns .form-control {
    background-color: black;
}

@media(max-width: 768px) {
    .SearchCampaigns {
        width: 100%;
        background-color:rgb(255, 100, 100)!important;
    }
}

.ui-autocomplete {
    background-color: white;
    border: 1px solid RGBA(0, 0, 0, 0.2);
    position: relative;
    cursor: default;
    overflow: auto;
    overflow-x: hidden;
    white-space: pre;
    overflow-y: auto;
    text-align: left;
    max-width: 720px;
    max-height: 500px;
}

ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
}

.noshow {
    display: none;
}

.ui-menu-items {
    padding: 5px;
    padding-left: 20px;
}

#quicksearch-autocomplete:focus, #quicksearch-autocomplete:hover{
    background:#50CABD;
}