/* .DropdownItems {
    display: none;
    position: relative;
} */

.DropdownItems a {
    size: 100px;
}

.dropdown-toggle{
    cursor:pointer;
}

.dropdown-toggle svg{
    color:RGBA(255,255,255,0.85);
}

.dropdown-toggle svg:hover{
    color:white;
}

.dropdown-toggle::after {
    display:none!important;
}

.font-weight-bold {
    font-weight: 700!important;
}

.feather-icon {
    margin-right: 20px;
    color: white;
}

.dropdown-news-item{
    width:100%;
    min-width:275px;
    max-width:500px;
    word-wrap: break-word;
    white-space: normal !important;
    padding: 0.75rem 1.5rem !important;
}

/* 768px or lower */
@media (max-width: 768px){
    .mobile-hide-dd{
        display: none;
    }
    /* .feather-icon{
        margin-left: 50px;
    } */
}

/* 768px or higher */
@media (min-width: 768px){ 
    .mobile-hide-dd {
        display: visible;
    }
}