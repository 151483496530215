.Toolbar {
    /* mb-bg-red */
    background-color: #fe3234; 
    /* fixed-top */
    /* position: fixed; */
    top: 0;
    right: 0;
    left: 0; 
    z-index: 1030;
    /* p-0 */
    padding: 0!important;
    /* justify-content: space-around; */
}

.fixed-top{
    position: fixed;
}

.navbar-brand {
    display: inline-block;
    background-color: #e92a2a;
    width: 220px;
    white-space: nowrap;
}

.navbar-brand .navbar-dark {
    color: #fff;
}

.Toolbar .mb-icon{
    text-decoration: none;
    height: 50px;
    align-content: center;
    margin: 0px;
}

a.maxmoney-badge-link:hover{
    text-decoration:none;
}

.maxmoney-badge{
    border-radius:20px !important;
    padding: 4px 10px !important;
    background:RGBA(0,0,0,0.3) !important;
    color: #dfb971 !important;
    font-weight:bold !important;
    font-size:13px !important;
    max-height:25px;
}

@media(max-width: 576px){
    .navbar-brand {
        padding-top: .75rem;
        padding-bottom: .75rem;
        font-size: 1rem;
        background-color: #e92a2a;
        width: 60px;
    }
    .desktopOnly{
        display: none;
    }
}

@media(max-width: 768px){
    .desktopOnly{
        display: none;
    }
}