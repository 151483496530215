.mb-bg-white {
    background: white;
}
.Search .table td, .table th {
    vertical-align: middle!important;
    text-align: left;
}

.custom-select {
    font-size: 14px!important;
}