.category .Popular{
    border-bottom:3px solid #0089fe
}


.category .Suggested{
    border-bottom:3px solid #ff9000
}


.category .New{
    border-bottom:3px solid #7200ff
}





