.alertPop {
    position: relative;
    /* max-width: 350px */
    width: 350px;
    background: RGBA(0, 0, 0, 0.85);
    color: white;
    text-align: left;
    overflow: hidden;
    margin-bottom: 10px;
}

.alertPop-header {
    padding: 5px 10px;
    background: rgba(0, 0, 0, 0.5);
    font-weight: bold;
}

.alertPop-body {
    padding: 10px;
    color: RGBA(255, 255, 255, 0.75);
    justify-content: space-between;
    display: flex;
}

.alertPopup-appear, .alertPopup-appear-active {
    /* opacity: 0; */
    /* position: relative; */
    bottom: -10px;
    right: -1000px;
}

.alertPopup-enter {
    right: -500px;
}

.alertPopup-enter-active {
    transition: 1000ms;
    right: 0px;
}

.alertPopup-enter-done {
    right: 0px;
}

/* If sliding out of view upon exit */

/* .alertPopup-exit {
    right: 0px;
}

.alertPopup-exit-active {
    right: -800px;
    transition: 1000ms;
} */

/* If fade it out upon exit */

.alertPopup-exit {
    opacity: .5;
    /* right: 0px; */
}

.alertPopup-exit-active {
    opacity: 0;
    transition: 1000ms;
}

.alert-container {
    position: fixed;
    top: 60px;
    right: 10px;
    z-index: 1000;
}