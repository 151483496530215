.campaign-left {
    padding-right: 0px!important;
}

@media(max-width: 992px) {
    .campaign-left {
        padding-right: 13px!important;
    }
}

.campaign-image {
    max-height: 241px;
    overflow: hidden;
}

.border-bottom {
    border-bottom: 1px solid #dee2e6;
}

div {
    display: block;
}

.flex-1 {
    flex: 1;
}

.height-limited {
    max-height: 350px;
    overflow-y: scroll;
}

/* table{
    display: table;
    border-spacing: 2px;
    border-color: grey;
    border-collapse: collapse;
} */

.table-responsive {
    display: block;
    width: 100%;
    overflow: auto;
    padding: 0;
}

.table {
    margin-bottom: 0px!important;
}

.text-info {
    text-transform: uppercase;
}

.w-100 {
    width: 100%!important;
}

/* .campaign-right {
    padding-left: 4px;
    margin-left: 0px!important;
} */

.badge-success {
    color: #fff;
    background-color: #28a745;
}

input[type=radio] {
    box-sizing: border-box;
    padding: 0;
}

input {
    overflow: visible;
}

#campaign-tracking-blur {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: RGBA(255, 255, 255, 0.80);
    z-index: 9;
    margin-top: -10px;
    border-radius: 4px;
}

.min-width-150 {
    min-width: 150px;
}

.tracking-link-floating {
    position: sticky;
    top: 60px;
    align-self: flex-start;
    z-index: 1;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .10);
    background: rgba(255, 255, 255, 1);
}

.max-width-100 {
    max-width: 100px;
}

.SingleCampaign .card-body {
    padding: 0;
}

/* For fadeout effect on clicking 'Build tracking link' button */

.fade-overlay-exit {
    opacity: 0.5;
    transition: all 500ms linear;
}

.fade-overlay-exit-active {
    opacity: 0.1;
}

.fade-overlay-exit-done {
    opacity: 0;
}

table#emailrules {
    table-layout: fixed;
    width: 100%!important;
}

table#emailrules td {
    width: 100%!important;
}

.rules-title {
    margin-right: -150px;
    font-weight: 600;
    font-size: 14px;
}